/*global gtag*/
import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { usePageSetup } from "@components/usePageSetup"
import { Layout } from "@components/Layout"
import { Seo } from "@components/Seo"
import { Hero } from "@components/Hero"
import { Arrow } from "@components/UI"
import { AltAboutJk } from "@components/AboutJk"
import { Lottie } from "@components/Lottie"
import { Button, ButtonAction } from "@components/Button"
import { splitText } from "@utils"
import repeatLottie from "@lottie/repeat_b.json"
import funnelLottie from "@lottie/funnel_b.json"
import heartPersonLottie from "@lottie/heart_person_b.json"
import dataLottie from "@lottie/data_b.json"

const EmployerBrandInternalBuyInGuidePage = ({ data }) => {
  const { employerBrandingDesktop, employerBrandingMobile, buyInGuideDesktop, aboutJkImage } = data

  const { themeColor } = usePageSetup({ themeColor: "bg-teal-light", displayGeneralForm: false })

  const downloadGuide = () => {
    const isBrowser = typeof window !== "undefined"
    if (!isBrowser) {
      return
    }
    gtag("event", "ungated_download", { event_category: "download", event_label: "EB_buy_in_guide" })
    window.open("/documents/jk-employer-branding-buy-in-guide.pdf", "_blank").focus()
  }

  const talkToExpert = () => {
    const isBrowser = typeof window !== "undefined"
    if (!isBrowser) {
      return
    }
    gtag("event", "talk_to_eb_expert", {event_category: "contact_us", event_label: "jk_expert_contact_submit"})
  }

  return (
    <Layout>
      <Seo title="Employer Brand Internal Buy-in-Guide" description="" />
      <section className={`${themeColor} overflow-hidden`}>
        <Hero className="pb-clamp-16-30 pt-clamp-16-26">
          <Hero.Title>
            <div className="lg:grid grid-cols-10">
              <div className="col-span-9">
                <h1
                  className="text-clamp-40-70"
                  data-aos="stagger"
                  dangerouslySetInnerHTML={{
                    __html: splitText({
                      "text-white": "Free guide:",
                      "text-gray-darkest": "Creating internal buy-in for your employer brand.",
                    }),
                  }}
                />
              </div>
            </div>
          </Hero.Title>
        </Hero>
      </section>

      <section className="overflow-hidden">
        <div className="relative container">
          <div className="bg-teal lg:bg-transparent absolute inset-0">
            <div className="bg-teal-light extend before:bg-teal-light after:bg-teal-light aspect-w-326 aspect-h-110 lg:aspect-w-1194 lg:aspect-h-141 w-full"></div>
          </div>
          <div className="w-full lg:w-10/12 mx-auto">
            <div className="relative flex flex-col-reverse lg:grid grid-cols-10">
              <div className="row-start-1 row-end-2 col-start-3 col-end-11 flex flex-col">
                <div className="hidden lg:block aspect-w-952 aspect-h-58"></div>
                <div data-aos="fade-left" className="relative h-full bg-teal lg:grid grid-cols-8">
                  <div className="col-start-4 col-end-8 flex items-center py-18 z-10">
                    <div>
                      <p>
                        Help your leaders understand the value of building an employer brand and remove barriers to buy-in
                        with this free guide.
                      </p>
                      <div className="mt-14">
                        <ButtonAction func={downloadGuide} className="bg-purple-light p-5 sm:hover:bg-white-dark">
                          Get the guide
                        </ButtonAction>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row-start-1 row-end-2 col-start-1 col-end-5 flex">
                <div data-aos="fade-right" className="w-full mb-auto">
                  <GatsbyImage image={getImage(buyInGuideDesktop)} alt="Buy-in Guide" />
                  <div className="hidden lg:block aspect-w-605 aspect-h-93"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Arrow colorTop="bg-transparent" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />

      <main className="overflow-hidden">
        <section className="pt-clamp-46-54">
          <div className="relative container flex">
            <div className="w-full lg:w-10/12 mx-auto">
              <div data-aos="fade-up">
                <p className="text-clamp-20-32 font-bold">
                  It will help you communicate the benefits of an employer brand, including:
                </p>
              </div>
              <div className="grid md:grid-cols-2 gap-x-4 gap-y-clamp-14-24 mt-clamp-20-23">
                <div data-aos="fade-up" className="flex flex-col md:flex-row items-center">
                  <div className="mr-auto" style={{ width: "134px" }}>
                    <Lottie animation={dataLottie} />
                  </div>
                  <div className="w-full flex-1 mt-6 md:mt-0 md:ml-8">
                    <div className="md:max-w-86">
                      <p>Why employer branding is so crucial in today’s climate</p>
                    </div>
                  </div>
                </div>
                <div data-aos="fade-up" className="flex flex-col md:flex-row items-center md:pl-8">
                  <div className="mr-auto" style={{ width: "134px" }}>
                    <Lottie animation={funnelLottie} />
                  </div>
                  <div className="w-full flex-1 mt-6 md:mt-0 md:ml-8">
                    <div className="md:max-w-86">
                      <p>Data-driven business benefits of employer branding</p>
                    </div>
                  </div>
                </div>
                <div data-aos="fade-up" className="flex flex-col md:flex-row items-center">
                  <div className="mr-auto" style={{ width: "134px" }}>
                    <Lottie animation={repeatLottie} />
                  </div>
                  <div className="w-full flex-1 mt-6 md:mt-0 md:ml-8">
                    <div className="md:max-w-86">
                      <p>How an employer brand boosts the bottom line</p>
                    </div>
                  </div>
                </div>
                <div data-aos="fade-up" className="flex flex-col md:flex-row items-center md:pl-8">
                  <div className="mr-auto" style={{ width: "134px" }}>
                    <Lottie animation={heartPersonLottie} />
                  </div>
                  <div className="w-full flex-1 mt-6 md:mt-0 md:ml-8">
                    <div className="md:max-w-86">
                      <p>The short- and long-term benefits of an employer brand</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pt-clamp-33-49">
          <div className="flex relative lg:hidden">
            <div className="absolute inset-0">
              <div className="container flex h-full">
                <div className="mt-auto bg-purple-light extend after:bg-purple-light w-full aspect-w-326 aspect-h-144"></div>
              </div>
            </div>
            <div data-aos="fade-right" className="w-3/4">
              <GatsbyImage image={getImage(employerBrandingMobile)} alt="Employer Branding" />
            </div>
          </div>
          <div className="container lg:grid grid-cols-12">
            <div className="relative hidden lg:block col-start-1 col-end-6 relative z-10">
              <div className="absolute inset-0 flex">
                <div data-aos="fade-right" className="absolute right-0">
                  <GatsbyImage image={getImage(employerBrandingDesktop)} alt="Employer Branding" />
                </div>
              </div>
            </div>
            <div className="relative bg-purple-light lg:grid col-start-2 col-end-13 grid-cols-11 lg:mt-24 py-clamp-18-38">
              <div className="absolute inset-0">
                <div className="w-full h-full bg-purple-light extend before:bg-purple-light lg:before:bg-transparent after:bg-purple-light"></div>
              </div>
              <div className="relative col-start-5 col-end-11 grid grid-cols-6">
                <div className="col-span-6 lg:pl-20">
                  <h2
                    data-aos="stagger"
                    className="text-title"
                    dangerouslySetInnerHTML={{
                      __html: splitText({
                        "text-white": "What’s your ",
                        "text-gray-darkest": "employer branding strategy?",
                      }),
                    }}
                  />
                  <div data-aos="fade-left" className="flex mt-10">
                    <Button link="/contact" func={talkToExpert} className="px-9 py-5 bg-gray-darkest text-left text-white sm:hover:text-white sm:hover:underline lg:ml-auto">
                      Talk to a JK expert
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Arrow colorTop="bg-transparent" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />
        <section className="pt-clamp-44-62">
          <AltAboutJk>
            <AltAboutJk.Default image={aboutJkImage} />
          </AltAboutJk>
        </section>
      </main>
    </Layout>
  )
}

export default EmployerBrandInternalBuyInGuidePage

export const EmployerBrandInternalBuyInQuery = graphql`
  query employerBrandInternalBuyInQuery {
    employerBrandingDesktop: file(relativePath: { eq: "hci/hci-employer-branding-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 830)
      }
    }
    employerBrandingMobile: file(relativePath: { eq: "hci/hci-employer-branding-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    buyInGuideDesktop: file(relativePath: { eq: "hci/buy-in-guide-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    buyInGuideMobile: file(relativePath: { eq: "hci/buy-in-guide-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    aboutJkImage: file(relativePath: { eq: "global/about-jk.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
